<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.basicInfo1')" />

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              :lg="customerType == 0 ? 10 : 12"
              :md="customerType == 0 ? 9 : 12"
              :sm="customerType == 0 ? 9 : 12"
            >
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-radio-group
                    row
                    label="Customer Type"
                    v-model="customerType"
                    :readonly="readOnly"
                  >
                    <v-radio label="Person" value="0"></v-radio>
                    <v-radio label="Company" value="1"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <!-- Customer Id -->
                <v-col class="py-0" cols="12" lg="3" md="6" sm="6">
                  <v-text-field
                    style="color: black"
                    :label="$vuetify.lang.t('$vuetify.CustomerID2')"
                    :rules="generalRule"
                    v-model="updataData.code"
                    :readonly="readOnly"
                  />
                </v-col>

                <!-- CustomerName -->
                <v-col class="py-0" cols="12" lg="3" md="6" sm="6">
                  <v-text-field
                    style="color: black"
                    :label="$vuetify.lang.t('$vuetify.customerName')"
                    :rules="generalRule"
                    v-model="updataData.company"
                    :readonly="readOnly"
                  />
                </v-col>

                <!-- Customer Sex -->
                <v-col
                  class="py-0"
                  v-if="customerType == 0"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                >
                  <v-select
                    :items="gender"
                    :label="$vuetify.lang.t('$vuetify.sex')"
                    :rules="generalRule"
                    v-model="updataData.sex"
                    :readonly="readOnly"
                  >
                  </v-select>
                </v-col>

                <!-- Birthday -->
                <v-col
                  class="py-0"
                  v-if="customerType == 0"
                  cols="12"
                  lg="3"
                  md="6"
                  sm="6"
                >
                  <v-menu
                    ref="birthday"
                    v-model="birthday"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    offset-y
                    class="ma-0 pa-0"
                    :disabled="readOnly"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                      class="ma-0 pa-0"
                    >
                      <v-text-field
                        append-icon="mdi-calendar"
                        v-model="updataData.birthday"
                        :label="$vuetify.lang.t('$vuetify.dateBirth')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="generalRule"
                      />
                    </template>

                    <v-date-picker
                      ref="BdPicker"
                      v-model="updataData.birthday"
                      :max="new Date().toISOString().substr(0, 10)"
                      :show-current="false"
                      @input="date = false"
                      class="ma-0 pa-0"
                      @change="saveBd"
                    />
                  </v-menu>
                </v-col>

                <!-- FAX -->
                <v-col class="py-0" cols="12" lg="3" md="6" sm="6">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.fax')"
                    v-model="updataData.fax"
                    :readonly="readOnly"
                  />
                </v-col>

                <!-- MailBox -->
                <v-col class="py-0" cols="12" lg="3" md="6" sm="6">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.email')"
                    v-model="updataData.email"
                    :rules="generalRule"
                    type="email"
                    :readonly="readOnly"
                  />
                </v-col>

                <!-- PhoneNumber -->
                <v-col class="py-0" cols="12" lg="3" md="6" sm="6">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.contactIphone')"
                    v-model="updataData.tel"
                    :rules="generalRule"
                    type="number"
                    :readonly="readOnly"
                  />
                </v-col>

                <!-- Address -->
                <v-col class="py-0" cols="12" lg="3" md="6" sm="6">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.address')"
                    v-model="updataData.address"
                    :rules="generalRule"
                    :readonly="readOnly"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col
              v-if="customerType == 0"
              cols="12"
              :lg="customerType == 0 ? 2 : 0"
              md="3"
              sm="3"
              class="d-flex justify-center align-center height-300"
            >
              <ImageUpload
                :image.sync="tempImage"
                :avatar="updataData.avatar"
                :disable="readOnly"
              />
            </v-col>
          </v-row>

          <!-- Contact Info -->
          <v-row>
            <v-col cols="12" class="pa-0 my-2 d-flex align-center">
              <span class="display-2 mr-auto">
                <v-icon left class="pb-1 display-2">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.contactInfo") }}
              </span>

              <v-btn color="primary" @click="newContact" :disabled="readOnly">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- Contact Person -->
          <v-row v-for="(item, key) in contacts_str" :key="key">
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.contactPerson')"
                v-model="item.contact"
                :rules="generalRule"
                :readonly="readOnly"
              />
            </v-col>

            <!-- Mobile -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.mobile')"
                v-model="item.tel"
                :rules="generalRule"
                :readonly="readOnly"
              />
            </v-col>

            <!-- Province -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.province')"
                v-model="item.province"
                :rules="generalRule"
                :readonly="readOnly"
              />
            </v-col>

            <!-- City -->
            <v-col class="py-0" cols="12" lg="3" md="4" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.city')"
                v-model="item.city"
                :rules="generalRule"
                :readonly="readOnly"
              />
            </v-col>

            <!-- District -->
            <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.district')"
                v-model="item.district"
                :rules="generalRule"
                :readonly="readOnly"
              />
            </v-col>

            <!-- Delivery Address -->
            <v-col class="py-0" cols="12" lg="6" md="5" sm="12">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.addr')"
                v-model="item.delivery_addr"
                :rules="generalRule"
                :readonly="readOnly"
              />
            </v-col>

            <v-col
              cols="12"
              v-if="contacts_str.length > 1"
              class="d-flex justify-end"
            >
              <v-btn
                color="red"
                outlined
                @click="deleteContact(item)"
                :disabled="readOnly"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- Invoice Info -->
          <v-row>
            <v-col cols="12" class="my-2 pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.invoiceInfo") }}
              </span>
            </v-col>
          </v-row>

          <!-- Invoice Title -->
          <v-row>
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.incoiceTitle')"
                v-model="paymentPartyDetail.company_name"
                :readonly="readOnly"
              />
            </v-col>

            <!-- Business Info -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.businessId')"
                v-model="paymentPartyDetail.business_code"
                :readonly="readOnly"
              />
            </v-col>

            <!-- Bank name -->
            <v-col class="py-0" cols="12" lg="4" md="4" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.bankName')"
                v-model="paymentPartyDetail.openning_bank"
                :readonly="readOnly"
              />
            </v-col>

            <!-- Bank AccNo -->
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.bankNo')"
                v-model="paymentPartyDetail.bank_acount"
                :readonly="readOnly"
              />
            </v-col>

            <!-- Bank Swift Code -->
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.bankSwiftCode')"
                v-model="paymentPartyDetail.swift_code"
                :readonly="readOnly"
              />
            </v-col>
          </v-row>

          <!-- Other -->
          <v-row>
            <v-col cols="12" class="pa-0 my-2">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.other") }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.remarks')"
                outlined
                v-model="updataData.note"
                :readonly="readOnly"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />

      <v-btn color="red darken-2" outlined @click="cancelForm">
        {{ $vuetify.lang.t("$vuetify.cancel") }}
      </v-btn>

      <v-btn color="green darken-2" @click="validate" :disabled="readOnly">
        {{ $vuetify.lang.t("$vuetify.save") }}
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
import serverConfig from "@/utils/serverConfig";
import { draggable } from "@/api/customFunction";

export default {
  components: {
    ImageUpload: () => import("@/components/imageUpload"),
    DialogTitle: () => import("@/components/dialogTitle"),
  },
  props: {
    dialog: Boolean,
    updataData: Object,
    paymentPartyDetail: Object,
    contacts_str: Array,
  },
  data() {
    return {
      readOnly: !this.permissionCheck("modify"),
      tempImage: undefined,
      blankIMG: serverConfig.blank_profile_img,
      imgUrl: serverConfig.img_url,
      valid: false,
      birthday: null,
      generalRule: [(v) => !!v || "this field is required"],
      sex: ["Male", "Female"],
    };
  },
  methods: {
    drag() {
      draggable();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.updataData.payment_party_detail = JSON.stringify(
          this.paymentPartyDetail
        );

        const arr = this.contacts_str.filter((res) => res.contact);
        this.updataData.contacts = JSON.stringify(arr);

        this.$emit("handleData", this.updataData, this.tempImage);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    cancelForm() {
      this.resetValidation();
      this.$emit("update:dialog", false);
    },
    newContact() {
      const obj = {
        contact: "",
        tel: "",
        province: "",
        city: "",
        district: "",
        deliery_addr: "",
      };
      this.contacts_str.length < 3
        ? this.contacts_str.push(obj)
        : alert("Contact Limit Reached");
    },
    deleteContact(item) {
      this.contacts_str.splice(this.contacts_str.indexOf(item), 1);
    },
    saveBd(date) {
      this.$refs.birthday.save(date);
    },
  },
  computed: {
    customerType: {
      get: function () {
        return this.updataData.customer_type.toString();
      },
      set: function (value) {
        this.updataData.customer_type = value;
      },
    },
    gender: {
      get: function () {
        const t = [
          { text: "Male", value: 1 },
          { text: "Female", value: 2 },
        ];

        return t;
      },
      set: function (value) {
        this.updataData.sex = value;
      },
    },
  },
  watch: {
    birthday(val) {
      val && setTimeout(() => (this.$refs.BdPicker.activePicker = "YEAR"));
    },
  },
};
</script>
